import React, { Fragment, useEffect, useState } from 'react';
import './Faq.css'
import { faqData } from '../../Constants';
import { ITabTitle } from '../../Interfaces';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import FaqComponent from '../../components/faq/Faq';
export default function FaqPage(props: ITabTitle) {
    const [currentUrl, setCurrentUrl] = useState('');
    const location = useLocation();
    useEffect(() => {
        let pageUrl = `${window.location.origin}${location.pathname}`;
        setCurrentUrl(pageUrl);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        document.title = props.tabTitle;
    }, [props.tabTitle]);
    const [faqs, setfaqs] = useState(faqData);
    const toggleFAQ = (index: number) => {
        setfaqs(faqs.map((faq, i) => {
            if (i === index) {
                faq.isOpen = !faq.isOpen
            } else {
                faq.isOpen = false;
            }
            return faq;
        }))
    }
    return (
        <Fragment>
            <Helmet>
                <title>FAQ PremierOne Tech | Your generic queries answered right</title>
                <link rel="canonical" href={currentUrl} />
                <meta name="description" content="Contact PremierOne Tech to discuss your IT needs. Our experts are ready to provide custom software solutions and tech consulting. Reach out today!" />
            </Helmet>
            <div className='faq-base' />
            <div className='question-graphic'>
                <img src="assets/images/question.svg" alt="ContactUs" loading='lazy' />
            </div>
            <div className='faq-main-container'>
                <span className='faq-subtitle'>Got Questions?</span>
                <span className='faq-title'>We've Got Answers!</span>
                <div className="faqs">
                    {faqs.map((faq, i) => (
                        <FaqComponent key={i} faq={faq} index={i} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
        </Fragment>
    );
}