import React from 'react';
import './ThatsAll.css';
import ContactButton from '../contact_button/ContactButton';
import { NavigateToContactUs } from '../../Utilities';

export default function ThatsAll() {

    return (
        <div className='thats-all-main-container'>
            <p className='thats-all-title'>That's All About Us</p>
            <br />
            <p className='thats-all-desc'>Thank you for your interest in PremierOne Tech. If you have any questions or would like to learn more about our services,
                we encourage you to get in touch with us.</p>
            <br />
            <ContactButton text='Get In Touch' onClick={NavigateToContactUs} enableAnimation={true} animationType='fade-up' textColor='var(--primary-color)' backgroundColor='var(--neutral-white)' showBorder={true} />
        </div>
    );
}
