
import "./Carousel.css";
import "@splidejs/react-splide/css";
import { Options, Splide, SplideSlide } from "@splidejs/react-splide";
import { ServiceList } from "../../Constants";
import ServiceCard from "../service_card/ServiceCard";
import { useEffect, useState } from "react";



export default function Carousel() {
    const [height, setHeight] = useState("");
    const [width, setWidth] = useState("");
    useEffect(() => {
        setHeight(window.innerWidth <= 460 ? "35dvh" : window.innerWidth > 460 && window.innerWidth < 1399 ? "35dvh" : "40dvh")
        setWidth(window.innerWidth <= 460 ? "55%" : window.innerWidth > 460 && window.innerWidth < 1399 ? "40%" : "30%");
    }, [])
    useEffect(() => {
        const handleResizeWidth = () => setWidth(window.innerWidth <= 460 ? "55%" : window.innerWidth > 460 && window.innerWidth < 1399 ? "40%" : "30%");
        window.addEventListener("resize", handleResizeWidth);
        return () => {
            window.removeEventListener("resize", handleResizeWidth);
        };
    });

    useEffect(() => {
        const handleResizeHeight = () => setHeight(window.innerWidth <= 460 ? "40dvh" : window.innerWidth > 460 && window.innerWidth < 1399 ? "30dvh" : "40dvh")
        window.addEventListener("resize", handleResizeHeight);
        return () => {
            window.removeEventListener("resize", handleResizeHeight);
        };
    });
    const options: Options = {
        arrows: false,
        pagination: false,
        fixedWidth: width,
        fixedHeight: height,
        gap: '0rem',
        type: 'loop',
        pauseOnHover: true,
        autoplay: true,
        interval: 1900,
    };
    return (
        <div>
            <Splide options={options} >
                {ServiceList.map((service, index) => (
                    <SplideSlide key={index} style={{ display: "flex" }}>
                        <ServiceCard buttonText="View Projects" desc={service.desc} title={service.title} image={service.image} height="90%" overlayColor={service.color} width="" centerAlign={true} shouldAnimateOnScroll={false} />
                    </SplideSlide>
                ))}
            </Splide>
        </div>
    );
};