import './Animation.css'
import { TypeAnimation } from 'react-type-animation';

export default function Animation() {
    return (
        <TypeAnimation
            sequence={[
                'Innovate ', 2000,
                'Integrate', 2000,
                'Succeed', 2000,
            ]}
            cursor={true}
            repeat={Infinity}
            className='home-animation-text'
        />
    );
}
