// ServicePage.jsx
import { Fragment, useEffect, useState } from 'react';
import './ServicePage.css';
import { ServiceList } from "../../Constants";
import ServiceCard from '../../components/service_card/ServiceCard';
import { ITabTitle } from '../../Interfaces';
import ThatsAll from '../../components/thats_all/ThatsAll';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function ServicePage(props: ITabTitle) {
    const [currentUrl, setCurrentUrl] = useState('');
    const location = useLocation();
    useEffect(() => {
        let pageUrl = `${window.location.origin}${location.pathname}`;
        setCurrentUrl(pageUrl);
        document.title = props.tabTitle;
        // eslint-disable-next-line
    }, [props.tabTitle]);
    return (
        <Fragment>
            <Helmet>
                <title>PremierOne Tech Services | Custom Software Development & IT Solutions</title>
                <link rel="canonical" href={currentUrl} />
                <meta name="description" content="Explore PremierOne Tech's IT services: custom software development, digital transformation, and tech consulting to drive your business success." />
            </Helmet>
            <div style={{ height: 'min-content', textAlign: 'center', padding: "5%" }}>
                <div className='service-page-container'>
                    {ServiceList.map((service, index) => (
                        <ServiceCard key={index} buttonText="View Projects" desc={service.longDesc} title={service.title} image={service.image} height="100%" overlayColor={service.color} width="100%" centerAlign={true} shouldAnimateOnScroll={false} />
                    ))}
                </div>
            </div>
            <ThatsAll />
        </Fragment>

    );
}
