import React, { Fragment, useEffect, useState } from 'react';
import './ContactUs.css'
import ContactForm from '../../components/contact_form/ContactForm';
import { ITabTitle } from '../../Interfaces';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
export default function ContactUs(props: ITabTitle) {
    const [currentUrl, setCurrentUrl] = useState('');
    const location = useLocation();
    useEffect(() => {
        let pageUrl = `${window.location.origin}${location.pathname}`;
        setCurrentUrl(pageUrl);
        // eslint-disable-next-line
    }, [])


    return (
        <Fragment>
            <Helmet>
                <title>Contact PremierOne Tech | Get in Touch with Our IT Experts</title>
                <link rel="canonical" href={currentUrl} />
                <meta name="description" content="Contact PremierOne Tech to discuss your IT needs. Our experts are ready to provide custom software solutions and tech consulting. Reach out today!" />
            </Helmet>
            <div className='contact-form-container'>
                <div className='contact-form-base' />
                <span className='contact-form-subtitle'>Didn't find what you are looking for?</span>
                <span className='contact-form-title'>More than happy to connect!</span>
                <ContactForm />
            </div>

        </Fragment>
    );
}