import React from "react";
import "./Preloader.css";

export interface IPreloader {
  load: boolean;
}

export default function PreLoader(props: IPreloader) {
  return (
    <div className="preloader-container">
      <div id={props.load ? "preloader" : "preloader-none"}></div>
      <div className="logo-container">
        <img
          src="/assets/logo/logo_full_dark.png"
          alt="logo_dark"
          style={{ display: 'block' }}
          loading='lazy'
        />
      </div>
    </div>
  );
}
