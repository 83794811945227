import React, { Fragment, useEffect } from 'react';
import 'aos/dist/aos.css'
import Aos from 'aos'
import './WhyChoose.css';
import { IWhyChooseCard } from '../../Interfaces';
import { whyChooseData } from '../../Constants';
import { config } from '../../Config';




export default function WhyChoose() {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    function WhyChooseCard(props: IWhyChooseCard) {
        return (
            <div data-aos="fade-up" >
                <div style={{ paddingLeft: '10%', marginBottom: "-5%", backgroundColor: "var(--neutral-white)" }}>
                    <div className="icon">
                        <props.icon size={35} color={config.color.primary_color}/>
                    </div>
                </div>
                <div className='why-choose-card-container'>
                    <p className='why-choose-card-title'>{props.title}</p>
                    <p className='why-choose-card-desc'>{props.desc}</p>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <div className="why-choose-content-wrapper">
                <p className="why-choose-title">Why Choose Us?</p>
                <p className="why-choose-desc">{'PremierOne Tech is a leading software consulting firm, specializing in innovative technology solutions. With expertise in software development and digital transformation, we help businesses achieve their goals. Our commitment to innovation, exceptional customer service, and tailored solutions sets us apart. Partner with PremierOne Tech to unlock your business\'s technology potential.'}</p>
                <div className='why-choose-card-holder'>
                    <div className='grid-gallery'>
                        {whyChooseData.map((item, index) => (
                            <WhyChooseCard key={index} desc={item.desc} icon={item.icon} title={item.title} />
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
