import React, { useState, useEffect, useRef } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import './CustomNavBar.css';
import { FaArrowRight } from 'react-icons/fa';
import { HandleScroll } from '../../Utilities';

const CustomNavbar: React.FC = () => {
  const location = window.location;
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const navbarRef = useRef<HTMLElement | null>(null);
  
  useEffect(() => {
    const cleanup = HandleScroll(setScrolled);
    return cleanup;
  }, []);


  const handleClickOutside = (event: MouseEvent) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const redirectToSubdomain = (pathPrefix: string) => {
    window.location.href = `https://${pathPrefix}.premieronetech.com/`;
  };

  return (
    <Navbar ref={navbarRef} expand="lg" fixed="top" className={scrolled ? 'scrolled' : ''} expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <Container>
        <Navbar.Brand href="/"><img src="/assets/logo/logo_full.png" alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav navbar-fixed-top" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-2" activeKey={location.pathname}>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about-us">About Us</Nav.Link>
            <Nav.Link href="/services">Services</Nav.Link>
            <Nav.Link onClick={() => redirectToSubdomain('blog')}>Blog</Nav.Link>
            <Nav.Link onClick={() => redirectToSubdomain('karim')}>Portfolio</Nav.Link>
          </Nav>
          <Nav className="m-2">
            <Nav.Link className="text-color-orange" href="/contact-us">Mail Us</Nav.Link>
            <Nav.Link className="call" onClick={() => window.open("tel:+918080977983", "_blank")}>
              <div style={{ marginRight: "0.5em" }}>{"Request a Call"}</div>
              <FaArrowRight style={{ transform: 'rotate(-40deg)', paddingLeft: "1%" }} size={"1em"} color="var(--neutral-white)" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
