export function NavigateToContactUs(e: Event) {
  window.location.href = '/contact-us'
  e.preventDefault();
}

export function HandleScroll(setScrolled: Function) {
  const scrollListener = () => {
    const offset = window.scrollY;
    setScrolled(offset > 100);
  };

  window.addEventListener('scroll', scrollListener);
  return () => {
    window.removeEventListener('scroll', scrollListener);
  };
}