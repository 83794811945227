import React, { useEffect } from 'react';
import './ContactButton.css'
import { IContactButton } from '../../Interfaces';
import 'aos/dist/aos.css'
import Aos from 'aos'
import { FaArrowRight } from 'react-icons/fa';

export default function ContactButton(props: IContactButton) {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);
    return (<button style={{ display: "flex", alignItems: "center", backgroundColor: props.backgroundColor, color: props.textColor, border: props.showBorder ? `2px solid ${props.textColor}` : '' }} data-aos={props.enableAnimation ? props.animationType : ""} className='contact-button' onClick={(e) => props.onClick(e)}>
        <div style={{ marginRight: "0.5em" }}>
            {props.text}
        </div>
        <FaArrowRight style={{ transform: 'rotate(-40deg)', paddingLeft: "1%" }} size={"1em"} color={props.textColor} />
    </button >
    );
}