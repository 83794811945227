import { useEffect, useState } from 'react';
import './Home.css';
import Animation from '../../components/text_animation/Animation';
import Services from '../../components/our_services/Services';
import TechPartner from '../../components/tech_partner/TechPartner';
import WhyChoose from '../../components/why_choose/WhyChoose';
import ThatsAll from '../../components/thats_all/ThatsAll';
import { techPartnerData } from '../../Constants'
import 'aos/dist/aos.css'
import Aos from 'aos'
import ContactButton from '../../components/contact_button/ContactButton';
import { NavigateToContactUs } from '../../Utilities';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import TestimonialsSlider from '../../components/testimonials_slider/TestimonialsSlider';
export default function Home() {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState('');
  useEffect(() => {
    let pageUrl = `${window.location.origin}${location.pathname}`;
    setCurrentUrl(pageUrl);
    Aos.init({ duration: 1000 });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        <meta name="description"
          content="PremierOne Tech: Innovative web/mobile development & digital marketing solutions. Contact us to leverage cutting-edge technology for success." />
      </Helmet>
      <div className='base' />
      <div className='home-container'>
        <div className='text-container'>
          <Animation />
          <h1 className='main-text'>
            <span style={{ fontFamily: 'var(--font-regular)', fontWeight: 500 }}>with </span>
            {'PremierOne Tech'}
          </h1>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ContactButton text='Contact Us' onClick={NavigateToContactUs} enableAnimation={true} animationType='fade' backgroundColor='var(--primary-color)' textColor='var(--neutral-white)' showBorder={false} />
          </div>

        </div>
        <div className='hero-image'>
          <img src="assets/images/main.svg" alt="Home" />
        </div>
      </div>
      <div ata-aos="fade-up" className='testimonial-container'>
        <TestimonialsSlider />
      </div>
      <div data-aos="fade-up" className='services-container'>
        <Services />
      </div>
      <div data-aos="fade-up" className='tech-partner-container'>
        <TechPartner {...techPartnerData} />
      </div>
      <div data-aos="fade-up" className='why-choose-container'>
        <WhyChoose />
      </div>
      <div data-aos="fade-up" className='thats-all-container'>
        <ThatsAll />
      </div>
    </div>
  );
}
