export const config = {
    color: {
        primary_color: '#F3AA56',
        primary_1_color: '#FFD67E',
        secondary_color: '#0D0B23',
        neutral_white: '#FFFFFF',
        neutral_black: '#0A0A0A',
        error: '#B80B09',
        warning: '#F9DC5C',
        success: '#74A57F',
    },
    font: {
        regular: 'RalewayRegular',
        thin: 'RalewayThin',
        light: 'RalewayLight',
        bold: 'RalewayBold',
        medium: 'RalewayMedium'
    },
    logo: {
        full: '/assets/logo/logo_full.png',
        full_dark: '/assets/logo/logo_full_dark.png',
        icon: '/assets/logo/logo_icon.png',
        icon_dark: '/assets/logo/logo_icon_dark.png',
        text: '/assets/logo/logo_text.png'
    }


}