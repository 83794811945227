import React, { useEffect } from "react";
import './ServiceCard.css';
import 'aos/dist/aos.css'
import Aos from 'aos'
import { IServiceCard } from "../../Interfaces";

export default function ServiceCard(props: IServiceCard) {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <div data-aos={props.shouldAnimateOnScroll ? "fade-up" : ''} className={`${props.smallButton ? 'card-container-small' : 'card-container'}`} style={{
            backgroundImage: `linear-gradient(${props.overlayColor}, ${props.overlayColor}), url(${props.image})`
        }}>
            <p className={`"align-text-center" ${props.smallButton ? 'card-title-small' : 'card-title'}`}>{props.title}</p>
            {props.desc ? <p className="card-desc">{props.desc}</p> : ''}
            {/* <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "5%" }}>
                <button className={`${props.smallButton ? 'card-button-small' : 'card-button'}`} onClick={() => {
                    window.location.href = '/services'
                }}>
                    {props.buttonText}
                    {props.desc ? <span style={{ width: '8px' }} /> : null}
                    <FaArrowRight style={{ transform: 'rotate(-40deg)' }} size={17} color={config.color.primary_color} />
                </button>
            </div> */}
        </div >
    );
}
