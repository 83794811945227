import React from 'react';
import './Footer.css';
import { FiArrowRight } from 'react-icons/fi';
import { FaFacebook, FaInstagram, FaLinkedinIn, } from 'react-icons/fa';

export default function Footer() {
    return (
        <div className='footer-container-main'>
            <div className="footer-container">
                <div className="footer-col-one">
                    <div><img src="/assets/logo/logo_full_dark.png" alt="logo_dark" height={"100%"} width={"50%"} loading='lazy' /></div>
                    <div style={{ paddingTop: "5%" }}>
                        <a style={{ color: 'var(--neutral-white)' }} href="tel:+918080977983" target='_blank' rel="noreferrer">(+91) 8080977983</a></div>
                    <div style={{ paddingTop: "5%" }}>
                        <a style={{ textDecoration: 'underline', color: 'var(--neutral-white)' }} href="mailto:info@premieronetech.com" target='_blank' rel="noreferrer">info@premieronetech.com</a>
                    </div>

                </div>
                <div className="footer-col-two">
                    <div style={{ fontFamily: "var(--font-bold)", fontStyle: 'italic' }}>Quick Links</div>
                    <div style={{ paddingTop: "10%", display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', rowGap: "10px", columnGap: "10px", opacity: '0.7' }}>
                        <a style={{ textDecoration: 'none' }} href='/services'>Service</a>
                        <a style={{ textDecoration: 'none', cursor: 'pointer' }} href='https://blog.premieronetech.com/'>Blog</a>
                        <a style={{ textDecoration: 'none' }} href='/about-us'>About Us</a>
                        <a style={{ textDecoration: 'none' }} href='/faq'>Faq's</a>
                        {/* <a style={{ textDecoration: 'none' }} href='/contact-us'>Careers</a> */}
                    </div>
                </div>
                <div className="footer-col-three">
                    <div style={{ fontFamily: "var(--font-bold)", marginBottom: "10%" }}>Subscribe</div>
                    <div className='inputWithButton'>
                        <input type="text" placeholder='Get Product Updates' />
                        <div> <FiArrowRight color='var(--neutral-white)' size={18} /></div>
                    </div>
                </div>
            </div>
            <div className='divider-line'></div>
            <div className='social-media-container'>
                <div className='social-left'>
                    <div className='icon-padding'>
                        <a href="https://www.instagram.com/premierone.tech/" target='_blank' rel="noreferrer"><FaInstagram color='var(--primary-color)' size={20} /></a>
                    </div>
                    <div className='icon-padding'>
                        <a href="https://www.facebook.com/profile.php?id=100093230859034" target='_blank' rel="noreferrer"><FaFacebook color='var(--primary-color)' size={20} /></a>
                    </div>
                    <div className='icon-padding'>
                        <a href="https://www.linkedin.com/company/premieronetech/" target='_blank' rel="noreferrer"><FaLinkedinIn color='var(--primary-color)' size={20} /></a>
                    </div>

                </div>
                <div className='social-right'>
                    <a href='/terms-of-service' className='icon-padding'>Terms of Service</a>
                    <a href='/privacy-policy' className='icon-padding'>Privacy Policy</a>
                    {/* eslint-disable-next-line */}
                    <a>© 2023 PremierOne Tech. All rights reserved</a>
                </div>
            </div>
        </div >
    );
}
