import React, { Fragment } from "react";
import "./PageNotFound.css";
import ContactButton from "../contact_button/ContactButton";

export default function PageNotFound() {
    return (
        <Fragment>
            <div className='base' />
            <div className="page-not-found">
                <img src="/assets/svg/404.svg" alt="404" />
                <p>Sorry, the page you are looking for does not exist.</p>
                <ContactButton
                    text='Home'
                    onClick={(e) => {
                        window.location.href = '/';
                        e.preventDefault();
                    }}
                    enableAnimation={false}
                    backgroundColor='var(--primary-color)'
                    textColor='var(--neutral-white)'
                    showBorder={false}
                />
            </div>
        </Fragment>
    );
}
