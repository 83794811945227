import React, { useState } from "react";
import emailjs from 'emailjs-com';
import './ContactForm.css'
import ContactButton from "../contact_button/ContactButton";

const formGroupStyle = {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    fontFamily: "var(--font-medium)"
};

const successMessageStyle = {
    padding: "1rem",
    margin: "1rem",
    backgroundColor: "var(--success)",
};
const errorMessageStyle = {
    padding: "1rem",
    margin: "1rem",
    backgroundColor: "var(--error)"
};

interface SuccessMessageProps {
    successText: string;
}
interface ErrorMessageProps {
    errorText: string;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ successText }) => (
    <div style={{ textAlign: "center", ...successMessageStyle }}>
        <span><b>{successText}</b></span>
    </div>
);

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorText }) => (
    <div style={{ textAlign: "center", ...errorMessageStyle }}>
        <span><b>{errorText}</b></span>
    </div>
);


interface FormElementProps {
    labelText: string;
    children: React.ReactNode;
    style?: Record<string, string>
}

const FormElement: React.FC<FormElementProps> = ({
    labelText,
    children,
    style
}) => (
    <>
        <div style={style ? { ...style, ...formGroupStyle } : formGroupStyle} >
            <div style={{ fontFamily: "var(--font-bold)" }}>{labelText}</div>
            <div>{children}</div>
        </div>
    </>
);

export default function ContactForm() {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [state, setState] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        message: "",
    });

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        event.preventDefault();
        const { name, value } = event.target;

        setState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    function sendEmail(event: React.ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        const { first_name, last_name, phone_number, email, message } = state;
        if (!first_name || !last_name || !phone_number || !email || !message) {
            setError(true);
            return;
        }
        emailjs
            .sendForm('service_zo5cexu', 'template_8x3pfuh', event.target, 'sX93VoC6VJwEcPw9x')
            .then((result) => {
                setSuccess(true);
                setState({
                    first_name: '',
                    last_name: '',
                    phone_number: '',
                    email: '',
                    message: '',
                });
                setTimeout(() => {
                    setSuccess(false);
                }, 5000);
            });
    }

    return (
        <div className="contact-form" >
            <form onSubmit={sendEmail}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormElement labelText="First Name" style={{ width: "45%" }}>
                        <input
                            className='input-field'
                            type="text"
                            name="first_name"
                            placeholder='First Name'
                            required
                            value={state.first_name}
                            onChange={handleInputChange}
                        />
                    </FormElement>
                    <FormElement labelText="Last Name" style={{ width: "45%" }}>
                        <input
                            className='input-field'
                            type="text"
                            name="last_name"
                            placeholder='Last Name'
                            required
                            value={state.last_name}
                            onChange={handleInputChange}
                        />
                    </FormElement>
                </div>
                <FormElement labelText="Email" >
                    <input
                        className='input-field'
                        type="email"
                        name="email"
                        placeholder='Email'
                        required
                        value={state.email}
                        onChange={handleInputChange}
                    />
                </FormElement>

                <FormElement labelText="Phone Number">
                    <input
                        className='input-field'
                        type="text"
                        name="phone_number"
                        placeholder='Phone Number'
                        required
                        value={state.phone_number}
                        onChange={handleInputChange}
                    />
                </FormElement>
                <FormElement labelText="Message">
                    <textarea
                        className='input-field message-field'
                        placeholder='Message'
                        rows={6}
                        name="message"
                        required
                        value={state.message}
                        onChange={handleInputChange}
                    />
                </FormElement>
                {error && <ErrorMessage errorText={"\nMissing Fields!"} />}
                {success && <SuccessMessage successText={"\nThank you for your interest.\nWe will get in touch shorty!"} />}
                <div className="contact-form-button">
                    <ContactButton text='Send Message' onClick={() => {}} enableAnimation={false} backgroundColor='var(--primary-color)' textColor='var(--neutral-white)' showBorder={false} />
                </div>
            </form>
        </div>
    );
};
