// Component
import React, { Fragment, useEffect } from 'react';
import './TechPartner.css';
import 'aos/dist/aos.css'
import Aos from 'aos'
import { ITechPartner } from '../../Interfaces';


export default function TechPartner(props: ITechPartner) {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <Fragment>
            <div className='tp-main-container'>
                <p className='tech-partner-title'>Your Friendly Tech Partner</p>
                <p className='tech-partner-desc'>
                    {'PremierOne Tech is a leading provider of custom software development services.\nOur team of skilled developers and analysts has a proven track record of delivering high-quality solutions that drive business growth and efficiency.'}
                </p>
                <div className='tech-partner-card-holder'>
                    {props.data.map((item, index) => {
                        return <div key={index} data-aos="fade-up" className='tech-partner-card'>
                            <div className='tech-partner-card-base'>
                                <div className='tech-partner-card-container' style={{ backgroundImage: `url(${item.image})` }}>  </div>
                                <div className='tech-partner-card-title'>{item.desc}</div>

                            </div>
                        </div>

                    })}
                    <div className='orange-container'></div>
                </div>
            </div>
        </Fragment>
    );
};
