import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { testimonials } from '../../Constants';
import './TestimonialsSlider.css';

export default function TestimonialsSlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 7000,
        autoplay: true, 
        fade: true,
        swipe: true,
        pauseOnDotsHover: true,
        pauseOnHover: true,
    };

    return (
        <div className="testimonials-slider">
            <h2 className="slider-title">What Our Clients Say</h2>
            <Slider {...settings}>
                {testimonials.map((item, index) => (
                    <div key={index} className="testimonial-card">
                        <div className="testimonial-content">
                            <div className="testimonial-text-content">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={item.image} alt="Client logo" className="client-logo" />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <h4 className="testimonial-client">{item.client}</h4>
                                        <p className="testimonial-info">{item.work} - {item.location}</p>
                                    </div>
                                </div>
                                <p className="testimonial-text">"{item.testimonial}"</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
