import React, { Fragment, useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Footer from './components/footer/Footer';
import ContactUs from './pages/contact_us/ContactUs';
import CustomNavbar from './components/custom_navbar/CustomNavBar';
import TextPage from './components/text_page/TextPage';
import * as Constants from './Constants';
import AboutUs from './pages/about_us/About';
import Home from './pages/home/Home';
import ServicePage from './pages/services/ServicePage';
import PreLoader from './components/preloader/Preloader';
import PageNotFound from './components/page_not_found/PageNotFound';
import { FloatingButton, WhatsAppButton } from './components/floating_button/FloatingButton';
import FaqPage from './pages/Faq/Faq';
import { HandleScroll } from './Utilities';

const App: React.FC = () => {
  const [load, setLoad] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  let counter: any = sessionStorage.getItem("counter") || 0;
  if (parseInt(counter) === 0) {
    sessionStorage.setItem("counter", (parseInt(counter) + 1).toString())
  }
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      const timer = setTimeout(() => {
        setLoad(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [location, setLoad]);

  useEffect(() => {
    const cleanup = HandleScroll(setScrolled);
    return cleanup;
  }, []);


  function handleScrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Fragment>
      {parseInt(counter) === 0 && load && location.pathname === '/' && <PreLoader load={load} />}
      <CustomNavbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs tabTitle="About Us | PremierOne Tech" />} />
        <Route path='/services' element={<ServicePage tabTitle="Services | PremierOne Tech" />} />
        <Route path='/contact-us' element={<ContactUs tabTitle="Contact Us | PremierOne Tech" />} />
        <Route path='/privacy-policy' element={<TextPage {...Constants.privacyPolicy} tabTitle={"Privacy Policy | PremierOne Tech"} />} />
        <Route path='/terms-of-service' element={<TextPage {...Constants.termsOfService} tabTitle={"Terms of Service | PremierOne Tech"} />} />
        <Route path='/faq' element={<FaqPage tabTitle="FAQ's" />} />
        <Route path='/404' element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <div className='floating-button-container'>
        <WhatsAppButton />
        {scrolled && <FloatingButton onClick={() => { handleScrollTop() }} />}
      </div>
      <Footer />
    </Fragment>
  );
};
export default App;
