import { useEffect, useState } from 'react';
import './About.css';
import 'aos/dist/aos.css'
import Aos from 'aos'
import { ITabTitle } from '../../Interfaces';
import Carousel from '../../components/carousel/Carousel';
import ThatsAll from '../../components/thats_all/ThatsAll';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
export default function AboutUs(props: ITabTitle) {
  const [currentUrl, setCurrentUrl] = useState('');
  const location = useLocation();

  useEffect(() => {
    let pageUrl = `${window.location.origin}${location.pathname}`;
    setCurrentUrl(pageUrl);
    Aos.init({ duration: 1000 });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    document.title = props.tabTitle;
  }, [props.tabTitle]);
  return (
    <div >
      <Helmet>
        <title>About PremierOne Tech | Innovative IT Solutions and Custom Software Development</title>
        <link rel="canonical" href={currentUrl} />
        <meta name="description" content="Discover PremierOne Tech, a leading provider of innovative IT solutions and custom software development. Learn about our expertise, values, and how we can help your business thrive with cutting-edge technology and personalized service." />
      </Helmet>
      <div className='base' />
      <h1 className='hidden-title'>About Us!</h1>
      <div className='about-container-one'>
        <div className='column column-text-left'>
          <p>We are a <b>leading</b> provider of{"\n"}<b>custom software</b> development{"\n"}<b>services</b></p>
        </div>
        <div className='image'>
          <img src="assets/images/aboutus-main.svg" alt="about" loading='lazy' />
        </div>
      </div>
      <div className='about-container-two'>
        <div className='about-container-two-row'>We Specialise in</div>
        <Carousel />
      </div>
      <div className='about-container-three'>
        <div className='orange-base left-zero' />
        <div className='image'>
          <img src="assets/images/aboutus-two.svg" alt="about" loading='lazy' />
        </div>
        <div className='column column-text-left'>
          <p>What sets apart from other companies? {"\n"}<b> Our unwavering commitment to excellence!</b>
          </p>
          <ul className='bullet-list'>
            <li>Technical expertise combined with industry trend understanding</li>
            <li>Solutions at the forefront of innovation</li>
            <li>Agile approach for adapting to evolving needs</li>
            <li>Quick and efficient results delivery</li>
          </ul>
        </div>
      </div>
      <div className='about-container-four'>
        <div className='orange-base right-zero' />
        <div className='column column-text-left'>
          <b>We strongly believe in</b>
          <ul className='bullet-list'>
            <li>Building long-term partnerships</li>
            <li>Prioritising open communication, transparency, and collaboration</li>
            <li>Ensuring satisfaction throughout the project lifecycle</li>
            <li>Dedicated team providing exceptional support</li>
            <li>Addressing queries and concerns promptly</li>
          </ul>
        </div>
        <div className='image'>
          <img src="assets/images/aboutus-three.svg" alt="about" loading='lazy' />
        </div>
      </div>
      <div style={{ padding: "10% 0" }}>
        <ThatsAll />
      </div>
    </div>
  );
}
