import React, { Fragment, useEffect, useState } from 'react';
import './TextPage.css';
import { ITextPage } from '../../Interfaces';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';


export default function TextPage(props: ITextPage & { tabTitle: string }) {
    const [currentUrl, setCurrentUrl] = useState('');
    const location = useLocation();
    useEffect(() => {
        let pageUrl = `${window.location.origin}${location.pathname}`;
        setCurrentUrl(pageUrl);
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        document.title = props.tabTitle;
    }, [props.tabTitle]);
    return (
        <Fragment>
            <Helmet>
                <title>{props.headingTitle} | PremierOne Tech </title>
                <link rel="canonical" href={currentUrl} />
                <meta name="description" content={props.headingTitle === "Privacy Policy" ? "View PremierOne Tech's Privacy Policy to learn how we handle and protect your personal information. Your privacy and data security are our priorities." : "Check PremierOne Tech's Terms of Service for our policies and user responsibilities. Understand your rights and our service agreements."} />
            </Helmet>
            <div className='text-page-main-container'>
                <div className='text-page-heading-container'>
                    <div className='text-page-heading-title'>{props.headingTitle}</div>
                </div>
                {props.textItems.map((item, index) => (
                    <div className='text-page-item-container' >
                        {item.title ? <div className='text-page-item-title'>{item.title}</div> : null}
                        <div className='text-page-item-desc'>{item.desc}</div>
                    </div>
                ))}
            </div>
        </Fragment>
    );
}
