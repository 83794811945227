


import React, { Fragment } from 'react'
import './Faq.css'
import { IFaq } from '../../Interfaces'

export default function FaqComponent(props: IFaq) {
    return (
        <Fragment>
            <div
                className={"faq " + (props.faq.isOpen ? 'open' : '')}
                key={props.index}
                onClick={() => props.toggleFAQ(props.index)}
            >
                <div className="faq-question">
                    {props.faq.question}
                </div>
                <div className="faq-answer">
                    {props.faq.answer}
                </div>
            </div>
        </Fragment>
    )
}





