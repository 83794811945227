import { FaArrowUp, FaWhatsapp } from 'react-icons/fa';
import './FloatingButton.css';

interface IProps {
    onClick: () => void
}
export function FloatingButton(props: IProps) {
    return (
        <button className="floating-button" onClick={props.onClick}>
            <FaArrowUp size={20}/>
        </button>
    );
};

export function WhatsAppButton() {
    return (
        <button className="whatsapp-button" onClick={() => {
            window.open("https://api.whatsapp.com/send?phone=8080977983", "_blank")
        }}>
            <FaWhatsapp size={30} />
        </button>
    );
};

