import { FiUserCheck } from "react-icons/fi";
import { IServiceList, ITechPartner, ITextPage, IWhyChooseCard, Testimonial } from "./Interfaces";
import { FaRegEye } from "react-icons/fa";
import { TbMessages } from "react-icons/tb";
import { RiCustomerService2Line } from "react-icons/ri";

export const privacyPolicy: ITextPage = {
    headingTitle: "Privacy Policy",
    textItems: [
        {
            title: "",
            desc: "PremierOne Tech is committed to protecting the privacy of our clients and website visitors. This privacy policy outlines the types of information we collect and how we use it."
        },
        {
            title: "Information We Collect",
            desc: "Personal information: We may collect personal information such as your name, email address, and phone number when you fill out a form on our website or contact us directly.\n\nUsage data: We may collect usage data such as the pages you visit on our website and the actions you take, as well as your IP address and browser type."
        },
        {
            title: "How We Use Your Information",
            desc:
                "To provide our services: We may use your personal information to communicate with you and provide our services, including custom software development, software integration, and business analysis and consultation.\n\nTo improve our website: We may use usage data to analyze how our website is being used and to make improvements.\n\nTo send marketing and promotional materials: We may use your personal information to send you marketing and promotional materials, such as newsletters and updates on our services. You may opt out of receiving these materials at any time."
        },
        {
            title: "Sharing Your Information",
            desc:
                "We do not sell or share your personal information with third parties for their own marketing purposes. We may share your information with service providers who assist us in providing our services, such as hosting providers and payment processors."
        },
        {
            title: "Security",
            desc:
                "We take steps to protect your personal information from unauthorized access and use. However, no website or internet transmission is completely secure, and we cannot guarantee the security of your"
        }
    ]
};

export const termsOfService: ITextPage = {
    headingTitle: "Terms of Service",
    textItems: [
        {
            title: "Acceptance of Terms",
            desc:
                'Please read these terms of service (the "Terms") carefully before using the PremierOne Tech website (the "Website"). By using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Website.'
        },
        {
            title: "Use of the Website",
            desc:
                "The Website is owned and operated by PremierOne Tech. The Website is intended for use by individuals seeking information about our services and for the business purposes of our clients.\n\nYou may not use the Website for any illegal or unauthorized purpose, or in any way that could damage, disable, overburden, or impair the Website. You may not attempt to gain unauthorized access to the Website or any servers or systems that host the Website."
        },
        {
            title: "Content",
            desc:
                "The content on the Website, including text, images, graphics, and other materials, is owned by PremierOne Tech or licensed to us by third parties. You may not use, reproduce, distribute, or modify the content on the Website without our express written permission."
        },
        {
            title: "Links to Third-Party Websites",
            desc:
                "The Website may contain links to third-party websites. These links are provided for your convenience and do not constitute an endorsement by PremierOne Tech of the linked website. We are not responsible for the content or privacy practices of these websites."
        },
        {
            title: "Disclaimer of Warranties",
            desc:
                "The Website and its content are provided on an 'as is' and 'as available' basis. PremierOne Tech makes no warranties, express or implied, regarding the Website or its content. We do not guarantee that the Website or its content will be error-free or uninterrupted."
        },
        {
            title: "Limitation of Liability",
            desc:
                "PremierOne Tech will not be liable for any damages arising from the use of the Website or its content. This includes, but is not limited to, direct, indirect, consequential, special, or punitive damages."
        }
    ]
};

export const techPartnerData: ITechPartner = {
    data: [
        {
            desc: 'Custom Software Development',
            image: '../../assets/images/softwaredev2.svg'
        },
        {
            desc: 'Innovation and Growth',
            image: '../../assets/images/innovation1.svg'
        },
        {
            desc: 'Elevating our Client\'s success to new heights',
            image: '../../assets/images/elevate1.svg'
        }
    ]
}

export const faqData = [
    {
        index: 1,
        question: "What services does PremierOne Tech offer?",
        answer: "PremierOne Tech offers a wide range of services including custom software development, software integration, software maintenance and support, business analysis and consultation, and web and mobile application development. We provide comprehensive solutions to meet the unique needs of businesses across various industries.",
        isOpen: false
    },
    {
        index: 2,
        question: "How can I request a custom software solution from PremierOne Tech?",
        answer: "Requesting a custom software solution from PremierOne Tech is simple. You can contact us through our website or email to discuss your requirements. Our team will engage in a detailed discussion to understand your project goals, assess your needs, and provide you with a tailored solution that aligns with your specific requirements.",
        isOpen: false
    },
    {
        index: 3,
        question: "Can PremierOne Tech integrate multiple software systems for my business?",
        answer: "Yes, PremierOne Tech specializes in integrating multiple software systems. We have expertise in seamlessly connecting different software platforms to ensure smooth and efficient operations across your organization. Our team will work closely with you to understand your existing systems and design a robust integration solution.",
        isOpen: false
    },
    {
        index: 4,
        question: "Does PremierOne Tech provide ongoing support and maintenance for software solutions?",
        answer: "Absolutely! PremierOne Tech offers comprehensive support and maintenance services for the software solutions we develop. We provide regular updates, bug fixes, and enhancements to ensure your software continues to run smoothly and effectively. Our dedicated support team is always available to address any queries or issues that may arise.",
        isOpen: false
    },
    {
        index: 5,
        question: "How can PremierOne Tech help improve my business operations through software?",
        answer: "PremierOne Tech can help enhance your business operations through strategic software solutions. Our experienced business analysts will analyze your current processes, identify areas for improvement, and recommend tailored software solutions to streamline operations, increase productivity, and drive business growth. We leverage technology to optimize your workflows and help you achieve your goals.",
        isOpen: false
    }
];

export const ServiceList: Array<IServiceList> = [
    {
        image: '../../assets/images/alexa.jpg',
        title: "Custom Development",
        desc: "We assist you in developing intelligent digital applications that enhance end-user experiences while increasing operational efficiency. Design, develop, and implement a future-ready digital strategy.",
        color: "rgba(255, 168, 105, 0.85)",
        longDesc: "Our custom software development services are designed to empower businesses with innovative and tailored technology solutions. We work closely with you to identify your unique requirements and deliver high-quality, scalable, and efficient software applications. With PremierOne Tech as your technology partner, experience the transformative power of customized software that drives your business goals."
    },
    {
        image: '../../assets/images/erp2.jpg',
        title: "ERP Implementation",
        desc: "Streamline your business processes with our comprehensive ERP implementation services, tailored to meet your unique needs and drive operational efficiency.",
        color: "rgba(255, 91, 121, 0.85)",
        longDesc: "Our ERP implementation services are designed to seamlessly integrate with your business operations, providing a unified platform to manage your core processes. We offer end-to-end solutions, from consultation and planning to deployment and support, ensuring a smooth transition and optimal utilization of your ERP system. With PremierOne Tech, achieve improved visibility, enhanced productivity, and better decision-making capabilities."
    },
    {
        image: '../../assets/images/erp1.jpg',
        title: "ERP Customisation",
        desc: "Enhance the functionality of your ERP system with our customisation services, tailored to align with your specific business requirements and goals.",
        color: "rgba(102, 187, 106, 0.85)",
        longDesc: "Our ERP customisation services are focused on modifying and extending your ERP system to better fit your unique business processes. Whether you need additional features, integrations, or custom workflows, we provide expert solutions to enhance your system's performance and usability. Trust PremierOne Tech to deliver bespoke ERP solutions that drive efficiency, improve user experience, and support your business growth."
    },
    {
        image: '../../assets/images/software.jpg',
        title: "Legacy Application Modernisation",
        desc: "Whether you need to reduce downtime or transition from monolith to microservices, rely on our expertise to redesign and recreate your current systems.",
        color: "rgba(138, 199, 255, 0.85)",
        longDesc: "Modernize your legacy applications with PremierOne Tech's expert guidance. Our team of skilled developers will transform your existing systems to be more efficient and adaptable. From reducing downtime to transitioning to microservices, we ensure seamless software integration and an enhanced user experience. Embrace the future of technology with our legacy application modernization services."
    },
    {
        image: '../../assets/images/web-dev.jpg',
        title: "Web Application Development",
        desc: "We maintain an always-ON focus on scalability and security to construct responsive web platforms that drive greater engagement and increased adoption rates.",
        color: "rgba(176, 148, 255, 0.85)",
        longDesc: "At PremierOne Tech, we excel in creating responsive web platforms that captivate your audience and drive greater engagement. Our web application development services focus on scalability and security to ensure seamless performance across devices. Enhance your online presence with our tailored web solutions that boost user adoption rates and drive business growth."
    },
    {
        image: '../../assets/images/mobile-dev.jpg',
        title: "Mobile Application Development",
        desc: "Partner with us to develop mobile apps that prioritize straightforward user onboarding, minimalist design, and self-guided workflows. We provide personalized experiences that span across multiple devices.",
        color: "rgba(255, 91, 121, 0.85)",
        longDesc: "Deliver personalized mobile experiences to your users with PremierOne Tech's mobile application development services. Our skilled team specializes in straightforward user onboarding, minimalist design, and self-guided workflows for enhanced usability. With our mobile apps, engage your audience across multiple devices and elevate user satisfaction to new heights."
    },
    {
        image: '../../assets/images/fullstack-dev.jpg',
        title: "Full Stack Development",
        desc: "Utilizing our extensive expertise in JavaScript frameworks, jQuery, PHP, Node, Angular, Python, and more, we assist in constructing a flexible and scalable back-end, as well as a responsive front-end.",
        color: "rgba(138, 199, 255, 0.85)",
        longDesc: "With PremierOne Tech's full stack development services, unlock the potential of comprehensive technology solutions. Our expertise in JavaScript frameworks, jQuery, PHP, Node, Angular, Python, and more ensures a flexible and scalable back-end combined with a responsive front-end. Seamlessly integrate all aspects of your applications with our full stack development expertise."
    },
    {
        image: '../../assets/images/software.jpg',
        title: "Cloud-Native Development",
        desc: "Partner with us to harness the scale, elasticity, resiliency, and flexibility cloud-native applications offer.",
        color: "rgba(255, 168, 105, 0.85)",
        longDesc: "Embrace the power of the cloud with PremierOne Tech's cloud-native application development services. We enable you to harness the scale, elasticity, resiliency, and flexibility that cloud-native applications offer. Modernize your infrastructure and elevate your business with our cloud-native solutions designed for the digital era."
    },
    {
        image: '../../assets/images/analysis.jpg',
        title: "Analysis & Consultation",
        desc: "Need help identifying areas where software can improve your business operations? Our team of business analysts can help you assess your needs and recommend the best solutions.",
        color: "rgba(176, 148, 255, 0.85)",
        longDesc: "Gain valuable insights and optimize your business operations with PremierOne Tech's analysis and consultation services. Our team of business analysts collaborates with you to identify areas where software solutions can drive efficiency and growth. Leverage our expert recommendations to implement the best-suited technology solutions for your business."
    },
    {
        image: '../../assets/images/maintenance.jpg',
        title: "Maintenance & Support",
        desc: "We offer ongoing support and maintenance services to ensure that your software continues to run smoothly and effectively over time.",
        color: "rgba(255, 91, 121, 0.85)",
        longDesc: "Ensure the longevity and seamless performance of your software with PremierOne Tech's software maintenance and support services. Our team provides ongoing support and proactive maintenance to keep your applications running smoothly and effectively. Partner with us for reliable and efficient support that guarantees your technology's continuous success."
    }
];

export const whyChooseData: Array<IWhyChooseCard> = [
    {
        title: "Expertise",
        desc: "We have extensive experience in designing and developing websites for clients across various industries",
        icon: FiUserCheck
    },
    {
        title: "Attention to Detail",
        desc: "We have extensive experience in designing and developing websites for clients across various industries",
        icon: FaRegEye
    },
    {
        title: "Communication",
        desc: "We have extensive experience in designing and developing websites for clients across various industries",
        icon: TbMessages
    },
    {
        title: "Customer Service",
        desc: "We have extensive experience in designing and developing websites for clients across various industries",
        icon: RiCustomerService2Line
    }
];



export const testimonials: Testimonial[] = [
    {
        client: 'Rujuta Diwekar',
        company: 'Rujuta Diwekar',
        location: 'India',
        work: 'Custom Appointment Scheduling Software',
        testimonial: 'We love what we do but the one thing that we always found cumbersome, time consuming (spent most weekends doing this) and most prone to errors was making appointment calendars.\nOur work involves programs that span for 3 months to a year, pre scheduling for the entire duration of the program meant accounting for the clients preferred slots, checking that there are no double bookings, physically blocking holidays for nutritionists, etc. A daunting task that required a ton of time and attention because like in any business in ours too we have to get started on the right foot with the client and the calendar was our first introduction to the program. One that set expectations and communicated that we are professionals and you can trust your health with us.\nWe approached many IT & app developers to provide solutions but no avail. Finally Karim helped us, he understood our problem, our culture, and worked tirelessly to come up with a customised solution that not just saved time but brought in daily joy. With a click, a year got booked, all holidays, preferences accounted for and entirely error free. He ensured that it’s easy both for us & for our clients to use it. He’s open to feedback and is willing to rework as many times to plug loose ends. We are eternally grateful for his time and effort and would wholeheartedly recommend him to anyone who has a problem that no one has been able to solve yet.',
        image: '/assets/logo/client_logo/rd.png'
    },
    {
        client: 'Deepak',
        company: 'Swicons Consultancy Services',
        location: 'India',
        work: 'React Website Maintenance',
        testimonial: 'Teaming up with PremierOne Tech to maintain our React website has been fantastic. Their prompt and expert service has kept our site running smoothly, with regular updates and fixes as needed. The difference in performance and reliability has been noticeable, and we’re really happy with how they’ve handled everything. If you’re looking for reliable web maintenance, they’re the ones to go to.',
        image: '/assets/logo/client_logo/swincons.png'
    },
    {
        client: 'Alisha',
        company: 'Concierge LLC',
        location: 'USA',
        work: 'Custom App Development',
        testimonial: 'PremierOne Tech has been incredible in developing a custom app for us at Concierge LLC. They truly understood our vision and created an app that connects our clients with top-notch medical professionals effortlessly. Their attention to detail and commitment to delivering a high-quality product have set us apart in the industry. We’re extremely satisfied with their work and the ongoing support we’ve received.',
        image: '/assets/logo/client_logo/concierge.png'
    },
    {
        client: 'Sameer',
        company: 'Sameer Hotel Supplies',
        location: 'India',
        work: 'ERPNext Implementation',
        testimonial: 'PremierOne Tech transformed how we manage our supply chain with their ERPNext implementation. The system they set up fits our needs perfectly and has made our processes much smoother. The integration was flawless, and their ongoing support has been top-notch. We’re really impressed with their work and would highly recommend them to anyone looking to upgrade their ERP system.',
        image: '/assets/logo/client_logo/shs.png'
    },
    {
        client: 'Shakil',
        company: 'Unique Packaging LLC',
        location: 'USA',
        work: 'ERPNext Implementation',
        testimonial: 'Working with PremierOne Tech for our ERPNext implementation was a game-changer for us at Unique Packaging LLC. Their team made the transition smooth and painless, and we now have real-time insights into our operations that have dramatically increased our efficiency. Their support has been outstanding, always ready to help and make adjustments as needed. We’re thrilled with the results and can’t recommend them enough!',
        image: '/assets/logo/client_logo/unique.png'
    },
    {
        client: 'Neil',
        company: 'Blaze Enterprises LLC',
        location: 'USA',
        work: 'ERPNext Implementation',
        testimonial: 'Our experience with PremierOne Tech for our ERPNext implementation has been excellent. They helped us streamline our business processes and the system now works like a charm. Their expertise and dedication were clear throughout the project, and the smooth deployment has been a huge boost for our growth. We’re really pleased with their work and would definitely recommend them.',
        image: '/assets/logo/client_logo/blaze.png'
    },
];