import React, { Fragment } from 'react';
import ServiceCard from '../service_card/ServiceCard';
import './Services.css'
import { ServiceList } from '../../Constants';
import _ from 'lodash'
export default function Services() {
    const cds = _.find(ServiceList, function (service) { return service.title === "Custom Development"; });
    const fsd = _.find(ServiceList, function (service) { return service.title === "Full Stack Development"; });
    const sms = _.find(ServiceList, function (service) { return service.title === "ERP Implementation"; });
    const bac = _.find(ServiceList, function (service) { return service.title === "Analysis & Consultation"; });
    let largeCardServices = [cds, fsd]
    let smallCardServices = [sms, bac]
    return (
        <Fragment>
            <p className='service-title'>Our Services</p>
            <div className='card-holder'>
                {largeCardServices.map((service, index) => (
                    <ServiceCard key={index} buttonText="View Projects" desc={service!.longDesc} title={service!.title} image={service!.image} height="100%" overlayColor={service!.color} width="100%" centerAlign={true} shouldAnimateOnScroll={false} />
                ))}
                <div className='small-card-holder'>
                    {smallCardServices.map((service, index) => (
                        <ServiceCard key={index} buttonText="View Projects" desc={window.innerWidth <= 460 ? service!.desc || "" : ""} title={service!.title} image={service!.image} height="49%" overlayColor={service!.color} width="100%" centerAlign={true} shouldAnimateOnScroll={false} />
                    ))}
                </div>
            </div>
        </Fragment>
    );
}
